import type { AppProps } from "next/app";
import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Amplify } from "aws-amplify";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";
import { CustomProvider } from "rsuite";
import { OverlayProvider } from "@toss/use-overlay";
import CustomModal from "src/domains/shared/components/customModal";
import GlobalStyle from "../styles/GlobalStyle";
import { amplifyConfig } from "../awsconfig";
import "public/libs/jquery-ui/jquery-ui.min.css";
import "public/libs/openlayers3/ol.css";
import "public/libs/spectrum/spectrum.css";
import "public/libs/jstree/themes/mixed/style.css";
import "public/libs/Cesium/Widgets/CesiumWidget/CesiumWidget.css";
import Script from "next/script";
import Head from "next/head";
import * as gtag from "src/domains/shared/utils/gtag";
import Locale from "src/domains/shared/Locale";

Amplify.configure(amplifyConfig);

export const queryClient = new QueryClient();

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <CustomProvider theme="dark">
            <GlobalStyle />
            <Script
              src="../libs/jquery/jquery-3.1.1.min.js"
              onLoad={() => {
                console.log("Script has jquery");
              }}
            />
            <Head>
              <title>DroneSquare</title>
              <meta
                name="description"
                content="드론 기반 인프라 자산관리 플랫폼"
              ></meta>
              <meta name="title" content="DroneSquare" />
              <meta
                name="keywords"
                content="DroneSquare, 드론스퀘어, 드론, Drone, 설계, 감리, 공간 데이터, 스마트시티, 빅데이터, BIM, 정사영상, 포인트클라우드, 메쉬, mesh, pointcloud"
              />
              <meta name="author" content="DroneSquare"></meta>
            </Head>
            {process.env.NODE_ENV === "production" && (
              <>
                <Script
                  strategy="afterInteractive"
                  src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                />
                <Script
                  id="gtag-init"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gtag.GA_TRACKING_ID}', {
                      page_path: window.location.pathname,
                    });
                  `,
                  }}
                />
              </>
            )}
            <OverlayProvider>
              <Component {...pageProps} />
            </OverlayProvider>
            <CustomModal />
            <ReactQueryDevtools initialIsOpen={false} />
            <ToastContainer position="top-right" hideProgressBar={true} />
          </CustomProvider>
        </Hydrate>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default MyApp;
